module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/render/project/src/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/render/project/src/src/images/azybaoLogo-Visual-600x600-JH-180703-v1.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a444a14f0a4293f873ac880dd4bf4289"},
    },{
      plugin: require('../node_modules/@primer/gatsby-theme-doctocat/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/azybaoLogo-Visual-600x600-JH-180703-v1.png"},
    }]
